import Body1 from "../body1"
import ProduitItem from "../produit-item"
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { urlBackend } from "../../data/utils-constants";
import { HEAD_LAYOUT } from "../../data/constant";

export default function MenuUniSex(props) {

    const [data,setData]=useState([]);
    const fetchData=useCallback(async()=>{
        let items=await axios.get(urlBackend+'/habits'); 
        items=items.data;
        const unisex=items.filter(value=>value.genre==='unisex');
        setData(unisex);
     },[]);
     useEffect(()=>{
          fetchData();
     },[fetchData])
    return(
        <>
        <Body1 title={HEAD_LAYOUT.unisex.title} video={HEAD_LAYOUT.unisex.video}/>
        <article className="my-4 d-flex justify-content-center items-start flex-wrap">
        {data && data.map(function(value,index){
                 return  <ProduitItem
                 id={value.id}
                 titre={value.titre}
                 description={value.description}
                 slogan={value.slogan}
                 prix={value.prix}
                 image={value.image}
                 nbr_etoile={value.nbr_etoile}
             />
           })} 
        </article>
        </>
    )
}