import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { urlBackend } from "../../data/utils-constants";
import { scrollDom } from "../../utils";
import DetailsItems from "../details-items";

export default function Details(props){
    const param=useParams();
    const id=param.id;
    const [data,setData]=useState({});
    const fetchData=useCallback(async()=>{
        let item=await axios.get(urlBackend+'/habits/'+id); 
        item=item.data; 
        setData(item);
        if(window){
            scrollDom();
        }
     },[]);
     useEffect(()=>{
          fetchData();
     },[fetchData])
    return(
        <>
            <DetailsItems data={data}/>
        </>
    );
}