export const HEAD_LAYOUT={
    man:{  
        title:"Elegance",
        video:'menu_man.mp4'
    },
    woman:{  
      title:"Arlo",
      video:'woman_vid.mp4'
  },
  unisex:{  
    title:"Unisex",
    video:'2.mp4'
  },
}
export const Auth={
  password: 'lolo@gmail.com',
  email: "lolo@gmail.com",
}