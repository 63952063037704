import { Link } from "react-router-dom";
import { DEVICE, genereArray } from "../data/constant-function";
import { capitalize } from "../data/utils-constants";

export default function ProduitItem({titre,description,id,image,slogan,prix,nbr_etoile}){
    return (
            <div className="col-md-3 col-sm-5 col-11 m-2">
                <div className="card mt-3">
                    <div className="product-1 align-items-center p-2 text-center">
                        <div className="img-container">
                           <img src={image} alt="" className="img"/>
                        </div>
                        <h5>{capitalize(titre)}</h5>
                        <div className="mt-3 info">
                            <span className="text1 d-block">
                               {capitalize(description)}
                            </span>
                            {/* <span className="text1">
                                {slogan}
                            </span> */}
                            <div className="cost1 mt-3 text-dark">
                                <span>
                                    {`${prix} ${DEVICE}`} 
                                </span>
                                <div className="star mt-3 align-items-center">
                                {genereArray(nbr_etoile).map((value,index)=> <i key={index} className="fas fa-star"></i>)}
                                </div>
                                <div className="mt-2 bissap1 text-center text-white mt-3 cursor">
                                    <Link to={`/details/${id}`} className="text-uppercase text-center p-3 d-block btn_habit text-white text-decoration-none">Acheter</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}