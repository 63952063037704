import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { urlBackend } from "../../data/utils-constants";
import { AdminParent } from "./adminParent";

export const FormUpdateHabit=({titre=""})=>{
  const param=useParams();
    const id=param.id;
    const [data,setData]=useState({titre:'',description:'',prix:"",image:"",genre:"",nbr_etoile:0})
    const [error,setError]=useState('');
    const [success,setSuccess]=useState('');
    const [up,setUp]=useState(false);
    const saisir=(e)=>{
       e.preventDefault();
       const {name,value,files}=e.target;
       if(name==='image'){
         console.log(files[0].name,"files[0].name")
         return setData(v=>{return {...v,filename:files[0].name,[name]:files[0]}});
       }
       return setData(v=>{return {...v,[name]:value}});
    }
    const send=async(e)=>{
       e.preventDefault();
       setError('');
       setSuccess('');
       if(up){
         return null;
       }
       const newData=new FormData();
       if(!data.titre || !data.description || !data.prix || !data.genre || !data.nbr_etoile){
          return setError("Veuillez renseigner les champs");
       }
       if(data.nbr_etoile>6){
         return setError("Le nombre d'etoile doit être inférieur à 5");
       }
       for(let key in data){
         if(key==='image'){
             newData.append(key,data[key]);
         }else{
            newData.append(key,data[key]);
         }
       }
      try{
         setUp(true);
         const item=await axios.post(urlBackend+'/habits/'+id,newData,{
            headers:{"Content-Type": "multipart/form-data",}
          });
          setUp(null);
          if(item.data && item.status===200){
               setSuccess('Mise à jour effectuée');
               setData(data);
           }else{
               setError("Une erreur s'est produit,actualise la page");
           }
      }catch(e){
         setError("Taille de l'image est trop grande");
      }
      setUp(false);
   }
   const fetchData=useCallback(async()=>{
      let item=await axios.get(urlBackend+'/habits/'+id); 
      item=item.data; delete item.image;
       setData(item);
   },[id]);
   useEffect(()=>{
        fetchData();
   },[fetchData])

    return<AdminParent titre={titre}>
      <div className="form-data">
        <form onSubmit={send} className="form">
          {up&&
            <div id="loading">
                <span id="loading-item">....</span>
            </div>
           }
           <div className="h2">Modifier</div>
           {error && <p className="error">{error}</p>}
           {success &&<p className='success'>{success}</p>}
           <div className="item-form">
               <div className="label"><label htmlFor="genre">Genre :</label></div>
               <select  name="genre" id="genre" value={data.genre || ''} onChange={saisir} >
                   <option value=''>Choisir</option>
                   {['man','woman','unisex'].map((value,index)=>
                     <option key={index} value={value}>{value}</option>
                   )}
               </select>
            </div>
            <div className="item-form">
               <div className="label"><label htmlFor="titre">Titre:</label></div>
               <input type="text" placeholder="Titre" name="titre" id="titre" value={data.titre || ''} onChange={saisir} />
            </div>
            <div className="item-form">
               <div className="label"><label htmlFor="prix">Prix:</label></div>
               <input placeholder="Exemple : 100" type="text" name="prix" id="prix" value={data.prix || ''} onChange={saisir} />
            </div>
            <div className="item-form">
               <div className="label"><label htmlFor="nbr_etoile">Nombre des étoiles :</label></div>
               <input type="text"  placeholder="Exemple: 4" name="nbr_etoile" id="nbr_etoile" value={data.nbr_etoile || ''} onChange={saisir} />
            </div>
            <div className="item-form">
               <div className="label"><label htmlFor="image">Images : {data.filename}</label></div>
               <input type="file" name="image" id="image" onChange={saisir} />
            </div>
            <div className="item-form">
               <div className="label"><label htmlFor="description">Description :</label></div>
               <textarea placeholder="Description" type="text" name="description" id="description" value={data.description || ''} onChange={saisir}></textarea>
            </div>
            <div className="btn">
               <button>Enregistrer</button>
            </div>
        </form>
      </div>
    </AdminParent>
}
