import { CONATACT,ADDRESS } from "../data/constant-function";

export default 
function Footer(){
    return(
        <footer className="footer-top pb-2">
  <div className="container text-white">
    <div className="row py-4">
      <div className="col-lg-8 footer-contact">
        <h3>Nous-contacter</h3>
        <p>{ADDRESS} <br/><strong>Phone:</strong> + {CONATACT}<br /> <strong>Email:</strong> Arlo.lolo1995@gmail.com<br /> </p>
      </div>
      <div className="col-lg-4 ">
        <h3 className="my-2">A propos TooTiks</h3>
        <p>Plateforme qui fait promotion de la mode urbaine africaine sous toutes ses facettes en offrant une variété sur tous les Outfits.</p>
      </div>
    </div>
  </div>
  <div className="container botom">
    <div className="copyright text-white"> © Tous droits réservés - 2024 <strong><span>TooTiks</span></strong>.</div>
  </div>
</footer>
    )
}