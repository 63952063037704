import { useCallback, useEffect, useState } from "react";
import { KEY_NAME_AUTH } from "../../data/utils-constants";
import { AdminParent } from "./adminParent"
export const Admin=({titre="PAGE ADMIN"})=>{
 const [auth,setAuth]=useState(null);
 const fetchData=useCallback(async()=>{

      if(window!==undefined){
        const token=localStorage.getItem(KEY_NAME_AUTH);
        if(token){
          setAuth(true);
         }else{
           setAuth(false);
        }
      }else{
        setAuth(false);
      }
   },[]);
   useEffect(()=>{
      fetchData();
   },[fetchData])

    if(auth===false){
      return <div className="text-center error404">Error 404</div>;
    }
    return<>
        <AdminParent titre={titre}>
          <div className="admin-bg-url"></div>
        </AdminParent>
    </>
}