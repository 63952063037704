import { RESEAUX } from "../data/constant-function";

export default
function Body1(props){
    
    return(
        <section className="main">
        <video src={`/videos/${props.video}`} autoPlay loop muted controls =' ' type="mp4"></video>
        <img alt="..." src="/images/mask.jpg" className="mask"/>
        <h2 id="pomlo">{props.title}</h2>
        <ul className="lop">
          {RESEAUX.map((val,index)=><li key={index}><a href={val.uri}><ion-icon name={val.name}></ion-icon></a></li>)}
        </ul>
    </section>
    )
}