import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEVICE } from "../../data/constant-function";
import { KEY_NAME_AUTH, urlBackend } from "../../data/utils-constants";
import { AdminParent } from "./adminParent";

export const ListeCommandes=({titre="Liste des commandes"})=>{
   const [auth,setAuth]=useState(null);
   const navigater=useNavigate();
   const [data,setData]=useState([])
   const supprimer=async(e,id)=>{
         e.preventDefault();
         let items=await axios.delete(urlBackend+'/commandes/'+id); 
         items=data.filter(value=>{return value.id!==id});
         setData(items);
   }
   const fetchData=useCallback(async()=>{
         let items=await axios.get(urlBackend+'/commandes'); 
         items=items.data;
         setData(items);
         if(window!==undefined){
            const token=localStorage.getItem(KEY_NAME_AUTH);
            if(token){
              setAuth(true);
             }else{
               setAuth(false);
            }
         }else{
            setAuth(false);
         }
   },[]);
   useEffect(()=>{
          fetchData();
   },[fetchData])
   if(auth===false){
      return <div className="text-center error404">Error 404</div>;
    }
return<AdminParent titre={titre}>
      <div className="list-data-container">
      <table>
        <thead>
            <tr>
               <th>Détail Commande</th>
               <th>Nom complet</th>
               <th>Numéro</th>
               <th>adresse</th>
               <th>action</th>
            </tr>
        </thead>
        <tbody>
         {data && data.map((value,index)=>{
             return <tr key={index}>
              <td className={'td-title'}>
               <img className="image-admin-habit" src={value.image} alt="..."/><br/>
               <span onClick={()=>navigater('/details/'+value.habit_id)} className="title">{value.titre}</span><br/>
                prix : {value.prix} {DEVICE}<br/>
                {data.date || ''}
              </td>
              <td>{value.nom} {value.prenom}</td>
              <td>{value.tel}</td>
              <td>{value.adresse}</td>
              <td>
                 <button className={'btn'} onClick={(e)=>supprimer(e,value.id)}>supprimer</button>
              </td>
             </tr>
         })}
        </tbody>
     </table>
      </div>
</AdminParent>
}