export const DEVICE='FCFA';
export const FRAI_LIVRAISON='1500'
export const CONATACT='22371723923';
export const ADDRESS="Mali/Bamako";

export const RESEAUX=[{
       uri:"https://web.facebook.com/moussa.lolo.319",
       name:"logo-facebook"},
       {
        uri: '#',
        name:'logo-twitter',
       },
       {
        uri: '#',
        name:'logo-instagram',
       }
]
export const genereArray=(a=0)=>{
    let count=[];
     for(let i=0;i<a;i++){
         count=[...count,i];
     }
     return count;
}