import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEVICE } from "../../data/constant-function";
import { KEY_NAME_AUTH, urlBackend } from "../../data/utils-constants";
import { AdminParent } from "./adminParent"
export const ListeHabits=({titre="Liste des habits"})=>{
   
   const [auth,setAuth]=useState(null);
   const [data,setData]=useState([])
   const navigater=useNavigate();
   const supprimer=async(e,id)=>{
         e.preventDefault();
         let items=await axios.delete(urlBackend+'/habits/'+id); 
         items=data.filter(value=>{return value.id!==id});
         setData(items);
   }
   const fetchData=useCallback(async()=>{
         let items=await axios.get(urlBackend+'/habits'); 
         items=items.data;
         setData(items);
         if(window!==undefined){
            const token=localStorage.getItem(KEY_NAME_AUTH);
            if(token){
              setAuth(true);
             }else{
               setAuth(false);
            }
         }else{
            setAuth(false);
         }
   },[]);
  
   useEffect(()=>{
          fetchData();
   },[fetchData])
    if(auth===false){
      return <div className="text-center error404">Error 404</div>;
    }
      return<AdminParent titre={titre}>
          <div className="list-data-container">
            <div className='ajouter-article'>
               <a href="/form-habit">Ajouter +</a >
            </div>
          <table>
            <thead>
                <tr>
                   <th>Titre</th>
                   <th>prix</th>
                   <th>Catégorie</th>
                   <th>action</th>
                </tr>
            </thead>
            <tbody>
             {data && data.map((value,index)=>{
                 return <tr key={index}>
                  <td className="td-title">
                     <img className="image-admin-habit" src={value.image} alt="..."/>
                     <span onClick={()=>navigater('/details/'+value.id)} className="title">{value.titre}</span><br/>
                  </td>
                  <td>{value.prix} {DEVICE}<br/></td>
                  <td>{value.genre}</td>
                  <td>
                     <button className={'btn modif'} onClick={()=>navigater('/form-upadte-habit/'+value.id)}>modifier</button>
                     <button className={'btn'} onClick={(e)=>supprimer(e,value.id)}>supprimer</button>
                  </td>
                 </tr>
             })
             }
            </tbody>
         </table>
          </div>
    </AdminParent>
}