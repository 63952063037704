import { useState } from "react";
import { useNavigate} from "react-router-dom";
import { KEY_AUTH, KEY_NAME_AUTH} from "../../data/utils-constants";
import { Auth } from "../../data/constant";

export const Login=({titre="Login"})=>{
    const [data,setData]=useState({email:'',password:''})
    const init={email:'',password:''};
    let navigate = useNavigate();
    const [error,setError]=useState('');
    const saisir=(e)=>{
       e.preventDefault();
       const {name,value}=e.target;
       if(name==="image"){
          return setData(v=>{return {...v,[name]:value}});
       }
       return setData(v=>{return {...v,[name]:value}});
    }
    const send=async(e)=>{
      e.preventDefault();
      setError('');
      if(!data.email || !data.password){
         return setError("Veuillez renseigner les champs");
      }
      if(data.email===Auth.email && data.password===Auth.password){
          localStorage.setItem(KEY_NAME_AUTH,KEY_AUTH);
          setData(init)
          window.location.href='/admin';
         //  navigate('/admin');
      }else{
         setError("Mot de passe ou email est invalide");
      }
    }
    return<div>
      <div className="form-data">
        <form onSubmit={send} className="form login">
           <h2>{titre}</h2>
           {error && <p className="error">{error}</p>}
            <div className="item-form">
               <label htmlFor="email">Email:</label>
               <input type="text" name="email" id="email" value={data.email || ''} onChange={saisir} />
            </div>
            <div className="item-form">
               <label htmlFor="password">Mot de passe :</label>
               <input type="password" name="password" id="password" value={data.password || ''} onChange={saisir} />
            </div>
            <div className="btn">
               <button>Login</button>
            </div>
        </form>
      </div>
    </div>
}