import { useNavigate } from "react-router-dom";

export const AdminParent=({titre="PAGE ADMIN",children})=>{
   const navigate=useNavigate();
   const logOut=(e)=>{
        e.preventDefault();
        localStorage.clear()
        navigate('/');
   }
    return<>
      <div className="admin">
         <h1>{titre}</h1>
         <div className='container-admin'>
           <div className="left-container">
               <a href="/admin">Admin</a>
               <a href="/liste-habits">Habits</a>
               <a href="/liste-commandes">Commandes</a>
               <a href="/liste-commandes" onClick={logOut}>Logout</a>
           </div>
           <div className="right-container">
               {children}
           </div>
         </div>
      </div>
    </>
}