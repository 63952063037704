import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { urlBackend } from "../../data/utils-constants";
import Body1 from "../body1";
import ProduitItem from "../produit-item";


export default function Index({propsData}) {
    const [data,setData]=useState([]);
    const fetchData=useCallback(async()=>{
        let items=await axios.get(urlBackend+'/habits'); 
        items=items.data;
        setData(items);
     },[]);
     useEffect(()=>{
          fetchData();
     },[fetchData])
    return(
        <>
            <Body1 title="Morocco" video='the_best.mp4'/>
            <article className="my-4 d-flex justify-content-center items-start flex-wrap">
            {data && data.map(function(value,index){
                 return  <ProduitItem key={index}
                 id={value.id}
                 titre={value.titre}
                 description={value.description}
                 slogan=''
                 prix={value.prix}
                 image={value.image}
                 nbr_etoile={value.nbr_etoile}
             />
           })} 
          </article>

        </>
       
    )
}