import { CONATACT, DEVICE, FRAI_LIVRAISON, genereArray } from "../data/constant-function";
import { capitalize } from "../data/utils-constants";
export default function DetailsItems({data,tel}) {
    const {id,description,image,titre,prix,nbr_etoile}=data;
    return(
    <div className="page-center">
        <article className="detail position-relative">
        <div className="col-md-10 col-11 m-auto box-first">
        <div className="w-100 p-2 d-flex justify-content-between flex-wrap text-center ">
            <div className="col-lg-4 col-12 bg-red-500">
                    <div className="container-img"><img alt='...' src={image} className="img"/></div>
                    {/* <h5 className="p-3">{capitalize(titre)}</h5> */}
                    <div className="star mt-3">
                    {genereArray(!nbr_etoile>5?nbr_etoile:3).map((value,index)=> <i key={index} className="fas fa-star"></i>)}
                    </div>
            </div>
            <div className="col-lg-6 col-12">
                <h5 className='w-100'>{capitalize(titre)}</h5>
                <span>
                  {`${prix} ${DEVICE}`} 
                </span>
                <h6>Livraison à domicile</h6>
                <br/>
                <div className="mb-2 text-center text-white bg-warning mt-3 cursor molp">
                    <a href={"/form-commande/"+id} className="text-uppercase text-center p-3 d-block no_derline text-ligth">Acheter</a>
                </div>
                <div className="bissap1 text-center text-white mt-1 bg-primary cursor">
                    <a href={`https://wa.me/${tel}`} target='_blank' className="text-uppercase text-center p-3 d-block no_derline text-light" rel="noreferrer">Whatsapp</a>
                </div>
                <div className="text-start" id="icon-box">
                    <i className="text-muted fal fa-truck fa-2x mt-3 " id="livraison"></i>
                    <span className="mx-3" id="liv">
                        Frais de livraison : {`${FRAI_LIVRAISON} ${DEVICE}`} 
                    </span>
                </div>
                <br/>
                <div className="text-start" id="icon-box">
                    <i className="fal fa-shield-check fa-2x"></i>
                    <span className="mx-3" id="liv">
                        Sécurité garantie
                    </span>
                </div>
            </div>
        </div>

        <div className="w-100 mt-3 px-3 ">
            <hr/>
            <h2>Description:</h2>
            <p className="text1 pb-2 w-full">
               {capitalize(description)}
            </p>
        </div>
    </div>
        </article>
    </div>
    )
}
DetailsItems.defaultProps={
     tel:CONATACT
}