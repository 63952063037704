import Body1 from "../body1"
import ProduitItem from "../produit-item"
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { urlBackend } from "../../data/utils-constants";
import { HEAD_LAYOUT } from "../../data/constant";

export default function MenuWoman(props) {
    const [data,setData]=useState([]);
    const fetchData=useCallback(async()=>{
        let items=await axios.get(urlBackend+'/habits'); 
        items=items.data;
        const woman=items.filter(value=>value.genre==='woman');
        setData(woman);
     },[]);
     useEffect(()=>{
          fetchData();
     },[fetchData])
    return(
      <>
        <Body1  title={HEAD_LAYOUT.woman.title} video={HEAD_LAYOUT.woman.video}/>
        <article className="my-4 d-flex justify-content-center items-start flex-wrap">
        {data && data.map(function(value,index){
                 return  <ProduitItem key={index}
                 id={value.id}
                 titre={value.titre}
                 description={value.description}
                 slogan={value.slogan}
                 prix={value.prix}
                 image={value.image}
                 nbr_etoile={value.nbr_etoile}
             />
           })} 
      </article>
      </>
    )
   
}