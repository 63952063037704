import {Link,useNavigate} from "react-router-dom"

export default 
function Header({auth}){
    const navigate = useNavigate();
    const open=(e)=>{
         e.preventDefault();
         let menu = document.querySelector('.menu');
         menu.classList.toggle('active');
    }
   const logout=()=>{
         localStorage.clear();
         window.location.href='/login';
        //  navigate('/login');
    }
    return <header> 
       
        <div id="listcat">
           <Link to="/" id="logo">
              <div id="logo-img"></div>
            </Link>
            <ul id="molopo">
                <li className="link-text">
                    <Link to="/menu-man">Man ||</Link>
                </li>
    
                <li className="link-text">
                    <Link to="/menu-woman">Women ||</Link>
                </li>
    
                <li className="link-text">
                    <Link to="/menu-unisex">Unisex ||</Link>
                </li>

                <li className="link-text">
                    <Link to="/menu-videos">Vidéos</Link>
                </li>
                
            </ul>
            <div className="header-login px-2">  {
                    auth?
                    <div className="flex link-text">
                       <span className="d-inline-block mx-2" onClick={()=>navigate('/admin')}>Admin</span>
                       <span onClick={logout}>LogOut</span>
                    </div>:
                    <Link to="/login">Login</Link>
                }
            </div>
        </div>
        <div className="container-menu">
             <div className="menu">
            <div onClick={open} className="toggle" ><ion-icon name="add-outline"></ion-icon></div>
            <li style={{"--i":1}}>
                <Link to="/"><ion-icon name="home-outline"></ion-icon></Link>
            </li>
    
            <li style={{"--i":3}}>
                <Link to="/menu-man"><ion-icon name="female-outline"></ion-icon></Link>
            </li>   
            
            <li style={{"--i":5}}>
                <Link to="/menu-woman"><ion-icon name="male-outline"></ion-icon></Link>
            </li>   

            <li style={{"--i":7}}>
                <Link to="/menu-unisex"><ion-icon name="male-female-outline"></ion-icon></Link>
            </li>
        </div>
        </div>
    </header>
}