import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { urlBackend } from "../../data/utils-constants";

export const EffectuerCommande=({titre="Lancez votre commande"})=>{
    const [data,setData]=useState({nom:'',prenom:'',adresse:"",tel:""})
    const init={nom:'',prenom:'',address:'',tel:''};
    const param=useParams();
    const id=param.id
    const [error,setError]=useState('');
    const [success,setSuccess]=useState('');
    const [up,setUp]=useState(false);
    const saisir=(e)=>{
       e.preventDefault();
       const {name,value}=e.target;
       if(name==="image"){
          return setData(v=>{return {...v,[name]:value}});
       }
       return setData(v=>{return {...v,[name]:value}});
    }
    const send=async(e)=>{
      e.preventDefault();
      setError('');
      setSuccess('');
      if(up){
         return null;
      }
      if(!data.nom || !data.prenom || !data.adresse || !data.tel){
         return setError("Veuillez renseigner les champs");
      }
      if(data.tel.length>15){
         return setError("Le nombre d'etoile doit être inférieur à 15");
      }
      setUp(true);
      const commande={...data,habit_id:id};
      const item=await axios.post(urlBackend+'/commandes',JSON.stringify(commande),{
         headers:{'Content-Type':'application/json'}
      });
      setUp(false);
      if(item.data && item.status===201){
           setSuccess('Commande envoyée');
           setData(init);
       }else{
           setError("Une erreur s'est produit,actualise la page");
       }
    }
    return<div className="page-center">
      <div className="form-data">
        <form onSubmit={send} className="form">
           {up&&
            <div id="loading">
                <span id="loading-item">....</span>
            </div>
           }
           <h2>{titre}</h2>
           {error && <p className="error">{error}</p>}
           {success &&<p className='success'>{success}</p>}
            <div className="item-form">
               <div className="label"><label htmlFor="nom">Nom:</label></div>
               <input type="text" name="nom" id="nom" value={data.nom || ''} onChange={saisir} />
            </div>
            <div className="item-form">
            <div className="label"><label htmlFor="prenom">Prénom :</label></div>
               <input type="text" name="prenom" id="prenom" value={data.prenom || ''} onChange={saisir} />
            </div>
            <div className="item-form">
               <div className="label"><label htmlFor="adresse">Adresse :</label></div>
               <input type="text" name="adresse" id="adresse" value={data.adresse || ''} onChange={saisir} />
            </div>
            <div className="item-form">
               <div className="label"><label htmlFor="tel">Tel :</label></div>
               <input type="text" name="tel" id="tel" value={data.tel || ''} onChange={saisir} />
            </div>
            <div className="btn">
               <button>Effectuer achat</button>
            </div>
        </form>
      </div>
    </div>
}