import './App.css';
import Header from './components/header'
import Produits from './components/Produits';
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { React, useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import Details from './components/pages/details';
import MenuMan from './components/pages/menuman';
import MenuWoman from './components/pages/menuwoman';
import MenuVideos from './components/pages/menuvideos';
import MenuUniSex from './components/pages/menuunisex';
import Index from './components/pages/index';
import { ListeCommandes } from './components/pages/liste-commandes';
import { ListeHabits } from './components/pages/liste-habits';
import { Admin } from './components/pages/page-admin';
import { FormHabit } from './components/pages/formHabit';
import { EffectuerCommande } from './components/pages/effectuerCommande';
import { FormUpdateHabit } from './components/pages/formUpdateHabit';
import { Login } from './components/pages/login';
import { KEY_NAME_AUTH } from './data/utils-constants';

function App() {
  const [auth,setAuth]=useState(null);
  const fetchData=useCallback(async()=>{
 
       if(window!==undefined){
         const token=localStorage.getItem(KEY_NAME_AUTH);
         if(token){
           setAuth(true);
          }else{
            setAuth(false);
         }
       }else{
         setAuth(false);
       }
    },[]);
    useEffect(()=>{
       fetchData();
    },[fetchData])
  return(

  <BrowserRouter>
    <div id='global-container'>
    <Header auth={auth}/>
    <Routes>
      <Route exact={true} path="/" element={<Index/>}/>
      <Route  path="/produits" element={<Produits/>}/>
      <Route  path="/details/:id" element={<Details/>}/>
      <Route  path="/menu-man" element={<MenuMan/>}/>
      <Route  path="/menu-woman" element={<MenuWoman/>}/>
      <Route  path="/menu-unisex" element={<MenuUniSex/>}/>
      <Route  path="/menu-videos" element={<MenuVideos/>}/>
      <Route  path="/liste-habits" element={<ListeHabits/>}/>
      <Route  path="/liste-commandes" element={<ListeCommandes/>}/>
      <Route  path="/form-habit" element={<FormHabit/>}/>
      <Route  path="/form-upadte-habit/:id" element={<FormUpdateHabit/>}/>
      <Route  path="/form-commande/:id" element={<EffectuerCommande/>}/>
      <Route  path="/login" element={<Login/>}/>
      <Route  path="/admin" element={<Admin/>}/> 
    </Routes>
    </div>
    <Footer/>
  </BrowserRouter>)
}

export default App;
